@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;700&family=Noto+Sans&display=swap');

.main-container {
	font-family: 'Noto Sans', Arial, 'Arial Unicode MS', sans-serif;
	height: 100vh;
}
/* Right side */
.right-div {
	background-color: #006c4b;
}

.form input {
	display: block;
	width: 100%;
	background-color: #fff;
	outline: 0;
	font-size: 1rem;
	padding: 0.32rem 0.75rem;
	border: 1px solid rgb(34, 34, 34, 0.15);
	color: #74706d;
	border-radius: 4px;
}

#file {
	cursor: pointer;
}

.img-div-main {
	width: 100%;
}
.img-div-sub {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.img-div-sub a,
p {
	color: #f8f8f7;
}

.graphic-img {
	width: 100%;
	height: auto;
}

.font {
	font-size: 1.5rem;
}

/* Left side */
.login-logo {
	width: 50%;
}

.loginform {
	margin-left: auto;
	margin-right: auto;
}

.loginform input {
	width: 100%;
	background-color: #fff;
	outline: 0;
	font-size: 1rem;
	padding: 0.32rem 0.75rem;
	border: 1px solid rgb(34, 34, 34, 0.15);
	color: #74706d;
	border-radius: 4px;
}

.input-div {
	position: relative;
}
.eye {
	position: absolute;
	right: 10px;
	top: 50%;
	transform: translateY(-50%);
	cursor: pointer;
}

.btn-login {
	transition: all 0.5 ease;
	background-color: #006c4b;
	color: #f8f8f7;
	padding: 0.32rem 0.75rem;
	cursor: pointer;
	font-size: 1rem;
	border: 1px solid #006c4b;
	border-radius: 4px;
	width: 100%;
}
