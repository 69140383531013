body {
	font-family: 'Muli', sans-serif;
	font-weight: 300;
}

/* Navbar */
.navbar {
	background-color: #006c4b;
}
.nav-link {
	color: #f8f8f7 !important;
	border: none;
	padding: 0;
	margin: 0;
	background: none;
	transition: all 0.2s ease;
}

.sidebar {
	float: left;
	width: 10%;
}

.text-bold {
	font-weight: bold;
}

.side-li {
	list-style-type: none;
}

.side-li a {
	color: black;
}

.side-btn {
	border: none;
	background-color: none;
	transition: all 0.2s ease;
}
.side-btn:active,
.nav-link:active {
	transform: translateY(-10px);
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

/* Content app*/
.content {
	margin-top: 10px;
	float: right;
	width: 90%;
}

/* Header Component */
.spinner {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: rgba(255, 255, 255, 0.5);
	z-index: 9999;
	backdrop-filter: blur(2px);
}
.spinner__div {
	display: inline-block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
}
.spinner img {
	animation: spin 4s linear infinite;
	width: 80px;
	height: auto;
}
.spinner-text {
	animation: flash 1s ease infinite;
	font-weight: bold;
	font-size: 1.5rem;
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
@keyframes flash {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

/* Pagination styles */
.pagination {
	display: flex;
	justify-content: flex-end;
	list-style: none;
	cursor: pointer;
}
.page-link {
	position: relative;
	display: block;
	color: #0d6efd;
	text-decoration: none;
	background-color: #fff;
	border: 1px solid #dee2e6;
	border-radius: 5%;
}
.active-link {
	color: white;
	background-color: #0d6efd;
}
.disabled-link {
	color: #6c757d;
}

/* Last row Order id column */
.td {
	color: #0d6efd;
}
.td:hover {
	cursor: pointer;
}

.footer-text {
	font-style: italic;
	color: #6c757d;
	font-size: 12px;
}

/* Media queries */
@media screen and (max-width: 991px) {
	.sidebar {
		display: none;
	}
	.content {
		width: 100%;
	}
}
@media screen and (min-width: 992px) {
	.ul {
		display: none;
	}
	.logout-div {
		margin-left: auto;
	}
}
@media screen and (max-width: 1332px) and (min-width: 992px) {
	.content {
		width: 85%;
	}
	.sidebar {
		width: 15%;
	}
}
@media screen and (max-width: 1400px) and (min-width: 1200px) {
	.qcol {
		max-width: 33%;
	}
}
